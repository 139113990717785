import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Layout from "./layout/Layout";

import Home from "./pages/home/Home";

import "./styles/main.scss";

function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route
            path="/"
            element={<Home />}  />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
