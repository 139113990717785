import { useEffect, useState } from "react";
import  { Navigate } from "react-router-dom";
import MailchimpSubscribe from "react-mailchimp-subscribe";

import "./home.scss";

const FormCheckbox = ({id, label, onChange}) => {
  return(
    <div className="form-checkbox">
      <label htmlFor={id}>
        <input id={id} type="checkbox" onChange={onChange} /> {label}
      </label>
    </div>
  )
}

const FormSubmit = ({label, onClick}) => {
  return(
    <div className="form-submit">
      <input type="submit" value={label} onClick={onClick} />
    </div>
  )
}

export default function Home(props) {

  const showResult = (status, message) => {
    switch(status) {
      case "success": return(
        <div className="mailchimp-result">
          <h3>Thank you for registering with DENZN. We are excited  to have you as part of our growing community.</h3>
          <p>Follow us on Discord to keep up to date with our latest digital collectibles and brand collaborations.</p>
        </div>
      )
      case "error": return(
        <div className="mailchimp-result">
          <h3>Error</h3>
          <p dangerouslySetInnerHTML={{ __html: message }} />
        </div>
      )
    }
  }

  const url = `https://equitbl.us14.list-manage.com/subscribe/post?u=2f593c363f32e6c2c78ddd5c5&amp;id=bf0e73e3c3`;

  const CustomForm = ({ status, message, onValidated }) => {

      const [isValidate, setValidate] = useState(false);

      const [email, setEmail] = useState("");
      const [firstName, setFirstName] = useState("");
      const [lastName, setLastName] = useState("");

      const [terms, setTerms] = useState(false);
      const [marketing, setMarketing] = useState(false);

      const handleSubmit = async (e) => {
        setValidate(true)
        e.preventDefault();
        email &&
        firstName &&
        lastName &&
        terms &&
        marketing &&
        email.indexOf("@") > -1 &&
        onValidated({
            MERGE0: email,
            MERGE1: firstName,
            MERGE2: lastName,
        });
      };

      const validateName = (name, setName) => {
        const validName = /[\S]{2,}/;
        if(validName.test(name.trim())) {
          setName(name);
        } else {
          setName("");
        }
      };

      const validateEmail = (email, setEmail) => {
        const validEmail = /[a-zA-Z0-9_\.\+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-\.]+/;
        if(validEmail.test(email.trim())) {
          setEmail(email);
        } else {
          setEmail("");
        }
      };


      return (
        <>
        {showResult(status, message)}
        {
          status !== "success" &&
            <form className="subscribe-form">
              
              <div className="form-input">
                <label>First Name</label>
                <input
                  type="text"
                  placeholder="Enter Name"
                  onChange={(e) => validateName(e.target.value, setFirstName)}
                />
                {(isValidate && !firstName) && <div className="-error">Please enter a valid First Name</div>}
              </div>

              <div className="form-input">
                <label>Last Name</label>
                <input
                  type="text"
                  placeholder="Enter Name"
                  onChange={(e) => validateName(e.target.value, setLastName)}
                />
                {(isValidate && !lastName) && <div className="-error">Please enter a valid Last Name</div>}
              </div>

              <div className="form-input">
                <label>Email</label>
                <input
                  type="email"
                  placeholder="Enter Email"
                  onChange={(e) => validateEmail(e.target.value, setEmail)}
                />
                {(isValidate && !email) && <div className="-error">Please enter a valid email address</div>}
              </div>

              <div className="form-checkbox">
                <label htmlFor="terms" className={(isValidate && !terms) ? "-error" : ""}>
                  <input
                    id="terms"
                    type="checkbox"
                    onChange={(e) => setTerms(e.target.checked)} /> Agree terms and conditions
                </label>
              </div>

              <div className="form-checkbox">
                <label htmlFor="marketing" className={(isValidate && !marketing) ? "-error" : ""}>
                  <input
                    id="marketing"
                    type="checkbox"
                    onChange={(e) => setMarketing(e.target.checked)} /> Marketing opti-in
                </label>
              </div>


              <FormSubmit
                label="Sign up"
                onClick={handleSubmit}
              />
        </form>
          }
        </>
      );
  };

  return (
    <div className="home">
      <img className="logo" src="/i/logo.png" width="245" height="137" />
      <h1>Smart digital collectibles</h1>
      <p>Welcome to a new world!</p>
      <p>Smart digital collectibles  from your favourite brands.</p>
      <p>Be part of the community.</p>
      <p>Be a DENZN.</p>
      <MailchimpSubscribe
          url={url}
          render={({ subscribe, status, message }) => (
              <CustomForm
                  status={status}
                  message={message}
                  onValidated={formData => subscribe(formData)}
              />
          )}
      />
    </div>
  )

};